<!--模板 公用信息-->
<template>
  <div class="template-base-config">

    <div class="g-ui-layout">
      <div class="g-layout-label">是否展示导航栏</div>
      <elSwitch v-model="componentData.navigationShow" :active-value="1" :inactive-value="0" />
    </div>

    <template v-if="Number(componentData.navigationShow) === 1">
      <div class="g-ui-layout">
        <div class="g-layout-label">导航栏标题</div>
        <el-input v-model="componentData.navigationTitle" placeholder="请输入导航栏标题" :maxlength="8" show-word-limit />
      </div>

      <div class="g-ui-layout">
        <div class="g-layout-label">导航栏字体颜色</div>
        <ColorPicker v-model="componentData.navigationFontColor"></ColorPicker>
      </div>


      <div class="g-ui-layout">
        <div class="g-layout-label">导航栏背景颜色</div>
        <ColorPicker v-model="componentData.navigationBackgroundColor"></ColorPicker>
      </div>
    </template>

  </div>
</template>

<script>
import { RadioButton, ColorPicker, Switch } from 'element-ui'
export default {
  name: 'templateBaseConfig',
  props: {
    componentData: Object
  },
  components: {
    'el-radio-button': RadioButton,
    ColorPicker,
    'elSwitch': Switch
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
  .template-base-config{
  }
</style>
